// eslint-disable-next-line import/no-cycle
import { encrypt, decrypt } from './crypto';
import { DBNAME, DBVERSION, OBJECT_STORES } from './constants';

export default (name = DBNAME) => {
	const dbName = name;
	const version = DBVERSION;
	let storageName = null;

	const open = (storage = storageName, key = 'id') => {
		storageName = storage;

		return new Promise((resolve, reject) => {
			const request = window.indexedDB.open(dbName, version);
			request.onerror = (event) => {
				reject(event.target.errorCode);
			};
			request.onsuccess = (event) => {
				resolve(event.target.result);
			};
			request.onupgradeneeded = (event) => {
				Object.keys(OBJECT_STORES).forEach((store) => {
					if (!request.result.objectStoreNames.contains(store)) {
						event.target.result.createObjectStore(OBJECT_STORES[store], {
							keyPath: key, autoIncrement: true,
						});
					}
				});
			};
		});
	};

	const load = (limit = 0, options = {
		order: 'desc', storage: storageName, key: 'id', decrypt: true,
	}) => new Promise((resolve, reject) => {
		try {
			const order = options.order || 'desc';
			const currentStorageName = options.storage || storageName;
			const shouldDecryptItems = Object.hasOwnProperty.call(options, 'decrypt') ? options.decrypt : true;
			const key = options.key || 'id';

			const decryptData = async (rows, _limit) => {
				let res = [];
				const content = rows
					? (order.toLowerCase() === 'desc' ? rows.reverse() : rows).slice(0, _limit > 0 ? _limit : rows.length)
					: [];
				if (shouldDecryptItems) {
					const promises = content.map((item) => decrypt(item.data));
					const decrypted = await Promise.all(promises);
					res = decrypted.map((item) => JSON.parse(item));
				} else {
					res = content?.map((item) => item.data) || [];
				}

				return res;
			};

			open(currentStorageName, key).then((db) => {
				const store = db.transaction([currentStorageName]).objectStore(currentStorageName);
				const request = store.getAll();
				request.onerror = (event) => {
					reject(event.target.errorCode);
				};
				request.onsuccess = (event) => {
					decryptData(event.target.result, limit).then((decryptedData) => {
						resolve(decryptedData);
					});
				};
			}).catch((error) => {
				reject(error);
			});
		} catch (e) {
			reject(e);
		}
	});

	// const save = (data, itemsToKeep = 0, options = {
	// 	name: dbName,
	// 	storage: storageName,
	// }) => {
	// 	return new Promise(async (resolve, reject) => {
	// 		try {
	// 			if (null === secret) {
	// 				secret = await fetchSecret();
	// 			}
	// 			const encryptedData = await (async (_data, _limit) => {
	// 				let res = [];
	// 				_data = Array.from(_data).splice(_limit > 0 ? (-1 * _limit)
	// 					: 0, _limit > 0 ? _limit : _data.length);
	// 				for (let i = 0; i < _data.length; i++) {
	// 					let item = _data[i];
	// 					let cypher = await encrypt(JSON.stringify(item), secret);
	// 					res.push({ id: item.id, data: cypher });
	// 				}
	// 				return res;
	// 			})(data, itemsToKeep);

	// 			open(options.name, options.storage).then(db => {
	// 				const transaction = db.transaction([options.storage], 'readwrite');
	// 				transaction.oncomplete = function (event) {
	// 					resolve(event.target.result);
	// 				};
	// 				transaction.onerror = function (event) {
	// 					reject(event.target.errorCode);
	// 				};
	// 				const store = transaction.objectStore(options.storage);
	// 				store.clear();
	// 				encryptedData.forEach(item => {
	// 					const req = store.put(item);
	// 					req.onerror = function (event) {
	// 						transaction.abort();
	// 						reject(event.target.errorCode);
	// 					};
	// 				});
	// 				transaction.commit();
	// 			}).catch(error => {
	// 				reject(error);
	// 			});
	// 		} catch (e) {
	// 			reject(e);
	// 		}
	// 	});
	// };

	const upsert = (item, options = {
		storage: storageName,
		key: 'id',
		encrypt: true,
	}) => new Promise((resolve, reject) => {
		try {
			const currentStorageName = options.storage || storageName;
			const shouldEncryptItem = Object.hasOwnProperty.call(options, 'encrypt') ? options.encrypt : true;
			const key = options.key || 'id';
			(async () => (shouldEncryptItem
				? encrypt(JSON.stringify(item))
				: item))().then((encryptedData) => {
				open(currentStorageName).then((db) => {
					const transaction = db.transaction([currentStorageName], 'readwrite');
					transaction.oncomplete = (event) => {
						resolve(event.target.result);
					};
					transaction.onerror = (event) => {
						reject(event.target.errorCode);
					};
					const store = transaction.objectStore(currentStorageName);
					const storable = Object.hasOwn(item, key)
						? { [key]: item[key], data: encryptedData }
						: { data: encryptedData };
					const req = store.put(storable);
					req.onerror = (event) => {
						reject(event.target.errorCode);
					};
					transaction.commit();
				}).catch((error) => {
					reject(error);
				});
			});
		} catch (error) {
			reject(error);
		}
	});

	const remove = (id, options = {
		storage: storageName,
		key: 'id',
	}) => new Promise((resolve, reject) => {
		try {
			const currentStorageName = options.storage || storageName;
			const key = options.key || 'id';
			open(currentStorageName, key).then((db) => {
				const transaction = db.transaction([currentStorageName], 'readwrite');
				transaction.oncomplete = (event) => {
					resolve(event.target.result);
				};
				transaction.onerror = (event) => {
					reject(event.target.errorCode);
				};
				const store = transaction.objectStore(currentStorageName);
				const req = store.delete(id);
				req.onerror = (event) => {
					reject(event.target.errorCode);
				};
				transaction.commit();
			}).catch((error) => {
				reject(error);
			});
		} catch (error) {
			reject(error);
		}
	});

	// const clear = (options = {
	// 	name: dbName,
	// 	storage: storageName,
	// }) => {
	// 	return new Promise(async (resolve, reject) => {
	// 		try {
	// 			open(options.name, options.storage).then(db => {
	// 				const transaction = db.transaction([options.storage], 'readwrite');
	// 				transaction.oncomplete = function (event) {
	// 					resolve(event.target.result);
	// 				};
	// 				transaction.onerror = function (event) {
	// 					reject(event.target.errorCode);
	// 				};
	// 				const store = transaction.objectStore(options.storage);
	// 				const req = store.clear();
	// 				req.onerror = function (event) {
	// 					reject(event.target.errorCode);
	// 				};
	// 				transaction.commit();
	// 			}).catch(error => {
	// 				reject(error);
	// 			});
	// 		} catch (error) {
	// 			reject(error);
	// 		}
	// 	});
	// };

	// const count = (options = {
	// 	name: dbName,
	// 	storage: storageName,
	// }) => {
	// 	return new Promise(async (resolve, reject) => {
	// 		try {
	// 			open(options.name, options.storage).then(db => {
	// 				const transaction = db.transaction([options.storage], 'readonly');
	// 				transaction.oncomplete = function (event) {
	// 					resolve(event.target.result);
	// 				};
	// 				transaction.onerror = function (event) {
	// 					reject(event.target.errorCode);
	// 				};
	// 				const store = transaction.objectStore(options.storage);
	// 				const req = store.count();
	// 				req.onerror = function (event) {
	// 					reject(event.target.errorCode);
	// 				};
	// 				transaction.commit();
	// 			}).catch(error => {
	// 				reject(error);
	// 			});
	// 		} catch (error) {
	// 			reject(error);
	// 		}
	// 	});
	// }

	// const find = (id, options = {
	// 	name: dbName,
	// 	storage: storageName,
	// }) => {
	// 	return new Promise(async (resolve, reject) => {
	// 		try {
	// 			open(options.name, options.storage).then(db => {
	// 				const transaction = db.transaction([options.storage], 'readonly');
	// 				transaction.oncomplete = function (event) {
	// 					resolve(event.target.result);
	// 				};
	// 				transaction.onerror = function (event) {
	// 					reject(event.target.errorCode);
	// 				};
	// 				const store = transaction.objectStore(options.storage);
	// 				const req = store.get(id);
	// 				req.onerror = function (event) {
	// 					reject(event.target.errorCode);
	// 				};
	// 				transaction.commit();
	// 			}).catch(error => {
	// 				reject(error);
	// 			});
	// 		} catch (error) {
	// 			reject(error);
	// 		}
	// 	});
	// };

	return {
		load,
		put: upsert,
		remove,
	};
};
