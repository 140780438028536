// import { Buffer } from 'buffer';
// eslint-disable-next-line import/no-cycle
import DB from './db';
import { OBJECT_STORES } from './constants';

const encode = (data) => {
	try {
		const enc = new TextEncoder();
		return enc.encode(data);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.warn(`Error encoding data : ${e.message}`);
		return data;
	}
};

const decode = (data) => {
	try {
		const dec = new TextDecoder();
		return dec.decode(data);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.warn(`Error decoding data : ${e.message}`);
		return data;
	}
};

const makeKey = async (extractable = false) => {
	try {
		return await window.crypto.subtle.generateKey(
			{
				name: 'AES-CBC',
				length: 256,
			},
			extractable,
			['encrypt', 'decrypt'],
		);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn(`Error making key: ${error.message}`);
		return '';
	}
};

const keyStore = OBJECT_STORES.cryptoKeys;

const base64encode = (str) => btoa(str);

const base64decode = (str) => atob(str);

export const generateSecret = () => {
	const str = Math.random().toString(36).substring(2, 15)
		+ Math.random().toString(36).substring(2, 15);
	return base64encode(str);
};

const storeKey = async () => {
	try {
		const key = await makeKey();
		const db = DB();
		await db.put({ id: 1, content: key }, {
			encrypt: false,
			storage: keyStore,
		});
		return key;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn(`Error storing secret : ${error.message}`);
		return '';
	}
};

const retrieveKey = async () => {
	try {
		const db = DB();
		const keys = await db.load(1, {
			storage: keyStore,
			decrypt: false,
		});

		if (keys.length === 0) {
			keys.push({ content: await storeKey() });
		}
		const key = keys[0].content;
		return key;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn(`Error retrieving key : ${error.message}`);
		return '';
	}
};

export const fetchKey = async () => {
	try {
		return await retrieveKey();
	} catch (e) {
		// eslint-disable-next-line no-console
		console.warn(`Error fetching key: ${e.message}`);
		return '';
	}
};

export const encrypt = async (data, key = null) => {
	try {
		const encKey = key || await fetchKey();
		const encoded = encode(data);
		const iv = window.crypto.getRandomValues(new Uint8Array(16));
		const encrypted = await window.crypto.subtle.encrypt({
			name: 'AES-CBC',
			iv,
		}, encKey, encoded);
		return base64encode(new Uint8Array([...iv, ...new Uint8Array(encrypted)]).toString());
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn(`Error encrypting data: ${error.message}`);
		return data;
	}
};

export const decrypt = async (data, key = null) => {
	try {
		const decKey = key || await fetchKey();
		const encData = new Uint8Array(base64decode(data).split(',').map(Number));
		const iv = encData.slice(0, 16);
		const encrypted = encData.slice(16);
		const decrypted = await window.crypto.subtle.decrypt({
			name: 'AES-CBC',
			iv,
		}, decKey, encrypted);
		return decode(decrypted);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn('Error decrypting data: ', error.message);
		// throw error;
		return data;
	}
};
